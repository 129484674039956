







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Mutation, namespace } from 'vuex-class';
import { setApiKeyFn, setLanguageFn, setOfflinePaymentFn } from '@/store/mutations';
import {
  addInitialValuesFn,
  addStepFn,
  setDeepStepInitialValuesFn,
  setDeepStepValuesFn,
  setStepInitialValuesFn,
  setStepValuesFn,
} from '@/store/modules/steps/mutations';
import BookingOptionTaxcard from '@/types/widget/BookingOptionTaxcard';
import { stepByIdFn } from '@/store/modules/steps/getters/stepById';
import validateStep from '@/components/initializer/validateStep';
import steps from '@/app/steps';
import WELCMpassRouter from '@/app/router/WELCMpassRouter';
import moment from 'moment';
import {
  COMMUNE_STEP,
  TAXCARD_ACCOMMODATION_DATA_STEP,
  TAXCARD_ADDRESS_DATA_STEP,
  TAXCARD_CONTACT_DATA_STEP,
  TAXCARD_GUEST_TYPE_STEP,
  TAXCARD_BOOKING_PERSON_STEP,
  TAXCARD_PETS_STEP,
  TAXCARD_STAY_DATA_STEP,
  TAXCARD_STEP,
  TAXCARD_USER_DATA_STEP,
} from '@/app/constants/StepIds';
import Loader from '@/components/Loader.vue';
import { RawLocation } from 'vue-router';
import { fetchConfigurationFn } from '@/store/actions';

const CommunesModule = namespace('communes');
const CheckoutModule = namespace('checkout');
const StepsModule = namespace('steps');

const taxcardInitialValues = (
  setDeepStepInitialValues: setDeepStepInitialValuesFn,
  setDeepStepValues: setDeepStepValuesFn,
  index: number
): ((id: string, values: any) => void) => {
  return (id: string, values: any) => {
    setDeepStepInitialValues({
      parentId: 'taxcard',
      id,
      index,
      values,
    });
    setDeepStepValues({
      parentId: 'taxcard',
      id,
      index,
      values,
    });
  };
};
@Component({
  components: { Loader },
})
export default class StoreInitializer extends Vue {
  @Prop(String)
  public apiKey!: string;

  @Prop(String)
  public language!: string;

  @Prop(Number)
  public communeId!: number;

  @Prop(Array)
  public taxcards!: BookingOptionTaxcard[];

  @Prop(Boolean)
  public offlinePayment!: boolean;

  @Mutation
  public setApiKey!: setApiKeyFn;

  @Mutation
  public setOfflinePayment!: setOfflinePaymentFn;

  @Mutation
  public setLanguage!: setLanguageFn;

  @Action
  public fetchConfiguration!: fetchConfigurationFn;

  @CheckoutModule.Action
  public doLogout!: any;

  @StepsModule.Mutation
  public setStepInitialValues!: setStepInitialValuesFn;

  @StepsModule.Mutation
  public setStepValues!: setStepValuesFn;

  @StepsModule.Mutation
  public setDeepStepInitialValues!: setDeepStepInitialValuesFn;

  @StepsModule.Mutation
  public setDeepStepValues!: setDeepStepValuesFn;

  @StepsModule.Mutation
  public addInitialValues!: addInitialValuesFn;

  @StepsModule.Getter
  public stepById!: stepByIdFn;

  @CommunesModule.Action
  public fetchCommunes!: any;

  @StepsModule.Action
  public changeCurrentStep!: Function;

  @StepsModule.Mutation
  public addStep!: addStepFn;

  public isLoading = false;

  public routeTo: RawLocation | null = null;

  @Watch('taxcards', { immediate: true })
  public onTaxcardsChange(taxcards: BookingOptionTaxcard[]): void {
    if (!taxcards) {
      this.addInitialValues({ id: TAXCARD_STEP });
      return;
    }
    taxcards.forEach((t, i) => {
      this.addInitialValues({ id: TAXCARD_STEP });
      const setTaxcardData = taxcardInitialValues(
        this.setDeepStepInitialValues.bind(this),
        this.setDeepStepValues.bind(this),
        i
      );
      setTaxcardData(TAXCARD_GUEST_TYPE_STEP, {
        guestType: t.guestType,
      });
      setTaxcardData(TAXCARD_USER_DATA_STEP, {
        salutation: t.salutation,
        title: t.title,
        firstName: t.firstName,
        lastName: t.lastName,
        birthday: t.birthday ? moment(t.birthday) : moment.invalid(),
        nationality: t.nationality,
      });
      setTaxcardData(TAXCARD_CONTACT_DATA_STEP, {
        phone: t.cellphoneNumber,
        mail: t.email,
      });
      setTaxcardData(TAXCARD_ADDRESS_DATA_STEP, {
        addressStreetAndNumber: t.addressStreetAndNumber,
        addressZip: t.addressZipCode,
        addressCity: t.addressCity,
        addressCountry: t.addressCountry,
      });
      setTaxcardData(TAXCARD_BOOKING_PERSON_STEP, {
        anonymous: t.isAnonymous,
        persons: t.personCount,
      });
      setTaxcardData(TAXCARD_PETS_STEP, {
        pets: t.petCount,
      });
      setTaxcardData(TAXCARD_STAY_DATA_STEP, {
        stayBegin: t.stayBegin ? moment(t.stayBegin) : moment.invalid(),
        stayEnd: t.stayEnd ? moment(t.stayEnd) : moment.invalid(),
      });
      setTaxcardData(TAXCARD_ACCOMMODATION_DATA_STEP, {
        accommodation: {
          id: t.accommodationId,
          name: t.accommodationName,
          addressStreetAndNumber: t.accommodationAddressStreetAndNumber,
          addressZip: t.accommodationAddressZip,
          addressCity: t.accommodationAddressCity,
        },
      });
    });
  }

  @Watch('apiKey', { immediate: true })
  public onApiKeyChange(apiKey: string): void {
    if (!apiKey) {
      return;
    }

    this.setApiKey(apiKey);
  }

  @Watch('offlinePayment', { immediate: true })
  public onOfflinePaymentChange(offlinePayment: boolean): void {
    this.setOfflinePayment(offlinePayment);
  }

  @Watch('language', { immediate: true })
  public onLanguageChange(language: string): void {
    if (!language) {
      return;
    }
    this.setLanguage(language);
  }

  @Watch('communeId', { immediate: true })
  public onCommuneIdChange(communeId: number): void {
    if (!communeId) {
      return;
    }

    this.setStepInitialValues({ id: COMMUNE_STEP, values: { communeId } });
    this.setStepValues({ id: COMMUNE_STEP, values: { communeId } });
  }

  public async created(): Promise<void> {
    await this.doLogout();

    this.isLoading = true;

    this.setApiKey(this.apiKey);

    const configuration = await this.fetchConfiguration();
    if (this.offlinePayment && !configuration.offlinePaymentAllowed) {
      console.error(
        'You configured the widget to allow offline payments but your api key is not enabled for that option'
      );
      return;
    }

    if (configuration.offlinePaymentAllowed) {
      this.addStep({ id: 'paymentOptIn', initialValues: { paymentType: null }, values: { paymentType: null } });
    }

    await this.fetchCommunes();

    await this.sleep(200);

    // setTimeout(() => {

    let allValid = true;
    steps.forEach((stepDefinition) => {
      if (this.routeTo) {
        return;
      }
      const { fields, id, steps: subStepDefinitions } = stepDefinition;
      const step = this.$store.state.steps[id];

      if (!fields && subStepDefinitions && subStepDefinitions.length > 0) {
        step.initialValues.forEach((subStepValues: any, index: number) => {
          subStepDefinitions.forEach((subStepDefinition) => {
            const isValid = validateStep(subStepDefinition, subStepValues[subStepDefinition.id], 'initialValues');

            if (allValid && !isValid) {
              this.routeTo = {
                name: subStepDefinition.id,
                params: {
                  parentStep: stepDefinition.id,
                  step: subStepDefinition.id,
                  iteration: `${index}`,
                },
              };
              allValid = false;
              return;
            }
          });
        });
      }

      const isValid = validateStep(stepDefinition, step, 'initialValues');
      if (allValid && !isValid && id !== 'checkout') {
        this.routeTo = { name: 'taxcard' };
        allValid = false;
        return;
      }
    });

    if (allValid) {
      this.routeTo = { name: 'taxcard' };
    }
    // }, 50);

    this.isLoading = false;

    if (this.routeTo) {
      this.changeCurrentStep(typeof this.routeTo === 'string' ? this.routeTo : this.routeTo.name);
      WELCMpassRouter.go(this.routeTo);
    } else {
      this.changeCurrentStep(COMMUNE_STEP);
      WELCMpassRouter.go({ name: COMMUNE_STEP });
    }
  }

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sleep(ms: number) {
    await this.timeout(ms);
  }
}
